/* styles.css */

/* Common Styles for Navigation */
.centered-nav {
    display: flex;
    justify-content: center;
}

.centered-nav .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Top Navigation Bar */
.top-nav {
    line-height: 64px; /* Match the Header height */
}

/* Bottom Navigation Bar */
.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
}

.bottom-nav .ant-menu-item {
    flex: 1;
    padding: 0;
}

.bottom-nav .nav-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottom-nav .nav-icon .anticon {
    font-size: 24px;
}

.bottom-nav .nav-label {
    font-size: 12px;
    margin-top: 4px;
}

/* Center the top nav items */
.top-nav .ant-menu-item {
    padding: 0 20px;
}

.top-nav .ant-menu-item .ant-menu-title-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-nav .ant-menu-item .anticon {
    margin-right: 8px;
}


/* Remove Underline for Selected Menu Items in Mobile View */
.bottom-nav .ant-menu-title-content::after {
    border-bottom: none !important; /* Removes the underline */
    background-color: transparent !important; /* Optional: Removes any background change */
    border-bottom-color: transparent !important;
}


