.how-to-guide-container {
    padding: 24px;
}

.how-to-guide-collapse {
    max-width: 800px;
    margin: 0 auto;
}

.panel-paragraph {
    font-size: 16px;
    line-height: 1.5;
}

.faq-item {
    margin-bottom: 16px;
}

.faq-question {
    font-size: 18px;
}

.faq-answer {
    font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .how-to-guide-container {
        padding: 16px;
    }

    .panel-paragraph,
    .faq-answer {
        font-size: 14px;
    }

    .faq-question {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .how-to-guide-container {
        padding: 8px;
    }

    .panel-paragraph,
    .faq-answer {
        font-size: 12px;
    }

    .faq-question {
        font-size: 14px;
    }
}
