.conceptRadarChartContainer {
    display: flex;
    justify-content: center;
    
}

.conceptRadarChartContainer .apexcharts-canvas {
    width: 60vw !important;
}

.conceptRadarChartContainer .apexcharts-datalabel {
    font-size: small;
    font-weight: bold;
    fill: black !important;
}

.conceptRadarTabContainer {
    visibility: visible;
}

.conceptRadarTabAccordion {
    visibility: collapse;
}

@media (max-width: 600px) {
    .conceptRadarTabContainer {
        visibility: collapse !important;
        display: none;
    }

    .conceptRadarTabAccordion {
        visibility: visible !important;
    }

    .conceptRadarTabAccordion .conceptRadarChartContainer {
        display: grid;
        justify-items: center;
        width: 100% !important;
        padding-top: 10px;
       
    }

    .conceptRadarChartContainer .apexcharts-canvas {
        width: 99vw !important;
        height: 100% !important;
    }

   
}

