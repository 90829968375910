/* Container for the header */
.headerContainer {
    width: 100%;
    padding: 0 20px;
}

/* Ensure the header row aligns items properly */
.headerRow {
    height: 1em; /* Adjust as needed */
}

/* Left section (logo) */
.headerLeft .logo {
    cursor: pointer;
    padding-top: 0.5em;
}

/* Center section (title) */
.headerCenter {
    text-align: center;
}

.headerTitle {
    color: var(--white);
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

/* Right section (icons or profile) */
.headerRight .headerIcon {
    font-size: 24px;
    color: var(--white);
}

.headerRight .headerIcon:hover {
    color: var(--primaryColor);
}

/* Responsive styles */
@media (max-width: 768px) {
    .headerTitle {
        font-size: 20px;
    }

    /*.headerLeft .logo {*/
    /*    width: 40px;*/
    /*}*/

    .headerRight .headerIcon {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .headerTitle {
        font-size: 18px;
    }


    .headerRight .headerIcon {
        font-size: 18px;
    }
}
