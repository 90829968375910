.strand-root {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.strand-header {
  margin-bottom: 20px;
}

.strand-title {
  margin: 0;
}

.strand-select {
  text-align: right;
}

@media (max-width: 767px) {
  .strand-root {
    padding: 15px;
  }

  .strand-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .strand-select {
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }
}

.strand-table .ant-table-thead > tr > th {
  padding: 12px;
  font-size: 16px;
}

.strand-table .ant-table-tbody > tr > td {
  padding: 12px;
  font-size: 16px;
}

.strand-table .ant-badge-ribbon-wrapper {
  display: block;
}

.strand-table .ant-badge-ribbon {
  top: -10px;
  right: -10px;
}

.strand-table a {
  font-size: 16px;
  color: #1890ff;
}

.strand-table a:hover {
  color: #40a9ff;
}


/* QuizTracker.css */


.dashboardQuizTracker {
  padding: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .dashboardQuizTracker {
    padding: 10px;
  }

  .ant-statistic-title {
    font-size: 14px;
  }

  .ant-statistic-content {
    font-size: 18px;
  }
}