.filterOptions {
    display: grid;
    justify-content: center;  
    margin: 0;
 }
 
 .filterOptionsRow {
    display: flex;
    padding: 0.3em;
    justify-content: center;
 }

 .filterOptionsRowTitle {
    display: flex;
    justify-content: center;
    margin: -1em auto;
 }
 
 .filterOptionsRow .ant-btn {
     width: 100%;
     box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12); 
 }

 @media(max-width: 560px) { 
   .ant-picker-panels { 
     flex-direction: column !important;
   } 
 }