
.conceptFilter {
    display: flex;
    padding: 0 2em;
    justify-content: center;
    font-size: larger;
}

.competencyChartContainer {
    display: flex;
    justify-content: center;
    width: max-content !important;
}

.competencyChartContainer .apexcharts-canvas {
    width: 49vw !important;
}

.competencyChartContainer .apexcharts-datalabel {
    font-size: large;
    font-weight: bold;
    fill: black !important;
}

.competencyChartContainer .apexcharts-toolbar {
    right: 10px !important;
}


@media (max-width: 600px) {
    .competencyChartContainer {
        display: grid;
        justify-items: center;
        width: 100% !important;
    }

    .competencyChartContainer .apexcharts-canvas {
        width: 99vw !important;
    }
}