.landing-page {
    background: url('./mathBG2.jpg') center/cover fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -2em;
  }
  
  .content-container {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
  }
  
  .welcomeTitle {
    font-size: 3em;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
    padding: 16px;
  }


  .welcomeTitleSecondary {
    font-size: 2em;
    font-weight: 350;
    color: #ffffff;
  }

  .landing-page .ant-btn {
    font-size: 1.5em;
    width: max-content;
    height: max-content;
    margin: 0.2em 0.5em;
  }

  .Typewriter {
    color: #ffffff;
    font-size: 2em;
    padding: 1em 0;
  }

  .Typewriter__cursor {
    color: rgb(99, 1, 148);
  }
  