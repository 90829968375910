/* Sticky Filters Container */
/* styles.css */

/* Sticky Filters Container */
.sticky-dashboard-filters {
    position: sticky;
    top: 4px; /* Slight offset from the top */
    z-index: 100;
    display: flex;
    justify-content: center;
    animation: fadeIn 0.5s ease-in-out;
}

/* Filter Card */
.filter-card {
    width: 100%;
    max-width: 1000px; /* Adjust as needed */
    background: linear-gradient(135deg, #e0f7fa, #a89bff); /* Gradient background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
    border-radius: 12px; /* Rounded corners */
}

/* Filter Container */
.filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px; /* Space between filters */
}

/* Make Form Items flex */
.filter-container .ant-form-item {
    flex: 1 1 180px; /* Allow items to grow and shrink, with a base width */
}

/* Filter Labels */
.filter-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.filter-label svg {
    margin-right: 8px; /* Space between icon and text */
    font-size: 18px;
}

/* Enhanced Select Components */
.filter-container .ant-select-selector {
    height: 40px !important; /* Uniform height */
    border-radius: 8px !important;
}

.filter-container .ant-select-selection-item {
    font-size: 14px;
    font-weight: 500;
}

/* Hover and Focus Effects */
.ant-select:hover .ant-select-selector {
    border-color: #009688 !important; /* Teal color on hover */
}

.ant-select-focused .ant-select-selector {
    border-color: #00796b !important; /* Darker teal on focus */
    box-shadow: 0 0 0 2px rgba(0, 150, 136, 0.2) !important; /* Teal shadow */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .filter-card {
        padding: 16px;
    }

    .filter-container {
        gap: 16px;
    }

    .filter-label {
        font-size: 14px;
    }

    .filter-container .ant-form-item {
        flex: 1 1 100%; /* Stack filters vertically on small screens */
    }

    .filter-container .ant-select {
        width: 100% !important; /* Full width on small screens */
    }
}

/* Optional: Fade-in Animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Enhanced Empty Message */
.empty-message {
    text-align: center;
    color: #999;
    font-size: 16px;
    padding: 20px;
}

/* Overview Container */
.overview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    padding: 1em;
    justify-content: center;
}

/* Chart Container */
.chart-container {
    background-color: #ffffff;
    padding: 1.5em;
    text-align: center;
    border-radius: 16px; /* Increased for smoother corners */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
    width: 100%;
    max-width: 600px; /* Max width for each chart */
    flex: 1 1 300px; /* Minimum width for responsiveness */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-container:hover {
    transform: translateY(-5px); /* Subtle hover effect */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12); /* Slightly stronger shadow on hover */
}

/* Chart Container */
.chart-container-radar {
    background-color: #ffffff;
    padding: 1.5em;
    text-align: center;
    border-radius: 16px; /* Increased for smoother corners */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
    width: 100%;
    max-width: 800px; /* Max width for each chart */
    flex: 1 1 300px; /* Minimum width for responsiveness */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-container-radar .apexcharts-canvas {
    min-width: 700px;
}

.chart-container-radar:hover {
    transform: translateY(-5px); /* Subtle hover effect */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12); /* Slightly stronger shadow on hover */
}

/* Tabs Styling */
.ant-tabs-tab {
    font-size: 1em;
    padding: 8px 16px;
    color: #595959;
    transition: color 0.3s ease;
}

.ant-tabs-tab:hover {
    color: #1890ff; /* Color transition on hover */
}

.ant-tabs-tab-active {
    color: #1890ff;
    font-weight: bold;
    background-color: #e6f7ff;
    border-radius: 8px;
    box-shadow: inset 0 -3px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow for active tab */
}

.ant-tabs-ink-bar {
    background-color: #1890ff; /* Active tab underline */
}

/* Chart Titles */
.chart-container p {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    margin-bottom: 1em;
}

/* Classroom Overview */
.classroom-overview-value {
    font-size: 2.2em;
    text-align: center;
    font-weight: bold;
    color: #333;
}

.classroom-overview-title {
    font-size: 0.9em;
    text-align: center;
    color: #666;
}

/* Overview Block */
.overview-block {
    display: flex;
    justify-content: space-around;
    gap: 1.5em;
    padding: 1em;
    background-color: rgba(240, 248, 255, 0.9); /* Light blue-gray */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* Card Style */
.classroom-overview-card {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 1.2em;
    border-radius: 12px; /* More rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 220px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.classroom-overview-card:hover {
    transform: translateY(-6px); /* Slight lift on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* More prominent shadow on hover */
    background-color: #befff1; /* Subtle background color change on hover */
}

/* Icon Styling */
.icon-container {
    font-size: 2.8em; /* Larger icon size */
    margin-right: 1em;
}

/* Count and Label Styling */
.count-and-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.classroom-overview-value {
    font-size: 1.8em; /* Larger font size for count */
    font-weight: bold;
    color: #333;
    margin-bottom: 0.2em;
}

.classroom-overview-title {
    font-size: 1em; /* Slightly larger font size for label */
    color: #666;
}

/* Performance Summary Containers */
.total-student-count-container, .quiz-attempt-container {
    display: grid;
    padding: 2em;
    text-align: center;
    gap: 1em;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Consistent with other sections */
}

.total-student-count-container .anticon,
.quiz-attempt-container .anticon {
    font-size: 50px;
    color: #52c41a; /* Color to differentiate this section */
}

/* Header Styling */
/* Container for the header */
.class-room-header {
    display: flex;
    justify-content: center;     /* Center the school name */
    align-items: center;         /* Vertically center items */
    position: relative;          /* Establish a positioning context */
    padding: 20px;
    background-color: #ffffff;   /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 8px;          /* Rounded corners */
    margin-bottom: 24px;         /* Space below the header */
}

/* Styling for the school name */
.header-school-name {
    font-size: 32px;                /* Large font size for prominence */
    font-weight: 700;               /* Bold text */
    color: #333333;                 /* Dark gray color for readability */
    margin: 0;                      /* Remove default margin */
    transition: transform 0.3s ease; /* Smooth scaling on hover */
    text-align: center;             /* Center text */
}

/* Styling for the date and time */
.header-date-time {
    position: absolute;             /* Position it absolutely within the header */
    right: 20px;                    /* Align to the right with some padding */
    font-size: 20px;                /* Slightly smaller than the school name */
    font-weight: 500;               /* Semi-bold for clarity */
    color: #555555;                 /* Medium gray color */
    transition: transform 0.3s ease; /* Smooth scaling on hover */
}

/* Hover effect for interactivity */
.class-room-header:hover .header-school-name,
.class-room-header:hover .header-date-time {
    transform: scale(1.05); /* Slightly enlarge on hover */
    cursor: pointer;        /* Change cursor to pointer */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .header-school-name {
        font-size: 28px;       /* Reduce font size on smaller screens */
    }

    .header-date-time {
        font-size: 18px;       /* Reduce font size on smaller screens */
        right: 10px;           /* Adjust positioning */
    }
}

@media (max-width: 480px) {
    .class-room-header {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;    /* Center items horizontally */
        padding: 16px;          /* Reduce padding */
    }

    .header-date-time {
        position: static;       /* Remove absolute positioning */
        margin-top: 10px;       /* Add space above the date/time */
        text-align: center;     /* Center text */
    }
}

/* Concept Radar Charts */
.radarchartContainerCS {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 1.5em;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

.radarchartContainerCS h3 {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    margin-bottom: 1em;
}

/* Empty message placeholder */
.empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em; /* Large font for visibility */
    font-weight: bold;
    color: #333;
    text-align: center;
    height: 100%; /* Full height of the container */
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding: 2em;
    background: linear-gradient(135deg, rgba(85, 75, 215, 0.3), rgba(135, 60, 250, 0.3), rgba(0, 147, 255, 0.3));
    backdrop-filter: blur(10px); /* Persistent blur effect */
}

.empty-message::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(85, 75, 215, 0.2), rgba(135, 60, 250, 0.2), rgba(0, 147, 255, 0.2));
    filter: blur(15px); /* Extra blur for softer look */
    z-index: -1;
    border-radius: 12px;
}

.empty-message p {
    z-index: 1;
    opacity: 0.95;
}