@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


@font-face {
  font-family: 'museo_sans_rounded';
  font-style: normal;
  font-weight: 500;
  src: local("museo_sans"), url("Fonts/museosansrounded500-webfont.woff2") format("woff"); }

@font-face {
  font-family: 'museo_sans_rounded';
  font-style: normal;
  font-weight: 100;
  src: local("museo_sans"), url("Fonts/museosansrounded100-webfont.woff2") format("woff"); }

@font-face {
  font-family: 'museo_sans_rounded';
  font-style: normal;
  font-weight: 300;
  src: local("museo_sans"), url("Fonts/museosansrounded300-webfont.woff2") format("woff"); }

@font-face {
  font-family: 'museo_sans_rounded';
  font-style: normal;
  font-weight: 700;
  src: local("museo_sans"), url("Fonts/museosansrounded700-webfont.woff2") format("woff"); }

@font-face {
  font-family: 'museo_sans_rounded';
  font-style: normal;
  font-weight: 900;
  src: local("museo_sans"), url("Fonts/museosansrounded900-webfont.woff2") format("woff"); }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
}