.reportLayout .scoreText {
    font-size: 48px; 
    margin: 0;
    font-weight: bolder;
    text-align: center;
}

.reportLayout .ant-card-body {
    padding-top: 0;
}

.reportLayout .feedbackText {
    font-size: medium;
    font-weight:500;
}

.reportLayout .trackerRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards horizontally */
    align-items: center; 
}

.reportLayout .trackerRow .ant-card {
    width: 100%; /* By default, each card takes 100% width */

    /* For screens greater than 350px */
    
}


.reportLayout .conceptWiseTabs {
    
}

/* @media (min-width: 351px) {
    .reportLayout .trackerRow .ant-card {
        width: 50%;
        
    }
} */

/* .reportLayout .ant-layout-sider-children {
    display: flex;
    justify-content: center;
    padding: 0.5em;
} */


.reportLayout .ant-layout-header {
    background-color: #fff !important;
    background: #fff !important;
}

.reportLayout .ant-layout-sider {
    background: rgb(51,4,83) !important;
    background: linear-gradient(90deg, rgba(51,4,83,1) 0%, rgba(158,0,255,1) 0%, rgba(51,4,83,1) 100%) !important;
}

.reportLayout .feedbackContainer .ant-card-head {
    margin: -1em 0;
}