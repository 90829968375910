/* styles.css */

/* Main Container */
.strength-analysis-container {
    padding: 20px;
    background-color: #f9f9f9;
}

/* Filters Section */
.filters-row {
    margin-bottom: 20px;
}

.filter-item {
    width: 100%;
}

.filter-comp {
    display: flex;
    flex-direction: column;
}

.filter-label-a {
    padding-left: 0.2em !important;
    font-size: smaller;
}
.filters-row .ant-select {
    width: 100%;
}

.filters-drawer-button {
    /* Make the button fixed at the top */
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays above other elements */
    border-radius: 8px; /* Remove border radius for full-width appearance */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.filter-comp > label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

/* Ensure Select components have consistent height */
.ant-select-lg .ant-select-selector {
    height: 40px;
}

/* Charts and Guide Section */
.charts-guide-row {
    margin-top: 20px;
}

/* Charts Section */
.charts-section {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Selected Concept Title */
.selected-concept-title {
    text-align: center;
    margin-bottom: 20px;
    color: #27ff18;
}

/* Radar Chart Card Styling */
.radarchartCard {
    background-color: #ffffff;
    padding: 25px; /* Increased padding */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

radarchartCard .ant-card-body {
    width: 100% !important;
}

.radarchartCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Radar Chart Title */
.radarChartTitle {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    color: #333;
}

/* How To Guide Card Styling */
.howToGuideCard {
    padding: 25px; /* Increased padding */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.howToGuideCard .how-to-guide-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ededed;
    text-align: center;
}

.howToGuideCard .how-to-guide-content {
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

/* Responsive Styles */

/* Medium Devices (Tablets) */
@media (max-width: 1200px) {
    .radarchartCard .apexcharts-canvas {
        width: 100% !important;
    }
}

/* Small Devices (Large Phones and Tablets) */
@media (max-width: 768px) {
    .strength-analysis-container {
        padding: 15px;
    }

    .filter-comp > label {
        font-size: 14px;
    }

    .ant-select-lg .ant-select-selector {
        height: 38px;
    }

    .selected-concept-title {
        font-size: 20px;
    }

    .radarChartTitle {
        font-size: 16px;
    }

    .howToGuideCard {
        padding: 20px; /* Reduced padding for smaller screens */
    }

    .howToGuideCard .how-to-guide-title {
        font-size: 16px;
    }

    .howToGuideCard .how-to-guide-content {
        font-size: 13px;
    }
}

/* Extra Small Devices (Phones) */
@media (max-width: 480px) {
    .strength-analysis-container {
        padding: 10px;
    }

    .filter-comp > label {
        font-size: 12px;
    }

    .ant-select-lg .ant-select-selector {
        height: 36px;
    }

    .selected-concept-title {
        font-size: 18px;
    }

    .radarChartTitle {
        font-size: 14px;
    }

    .howToGuideCard {
        padding: 15px; /* Further reduced padding */
    }

    .howToGuideCard .how-to-guide-title {
        font-size: 14px;
    }

    .howToGuideCard .how-to-guide-content {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .filters-row > .ant-col {
        margin-bottom: 16px;
    }
    .selected-concept-title {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .selected-concept-title {
        font-size: 16px;
    }
}