:root {
  --primaryColor: #000000;
  --secondaryColor: rgb(99, 1, 148);
  --white: #ffffff;
  --headerBackgroundColor: var(--secondaryColor);
  --headerLogoBackgroundColor: var(--primaryColor);
  --mainBackgroundColor: linear-gradient(90deg, rgba(0, 0, 36, 1) 0%, rgba(76, 9, 121, 1) 35%, rgba(158, 0, 255, 1) 100%);
  --mainBackgroundColor2: linear-gradient(90deg, rgba(0, 0, 36, 1) 0%, #F3E2FF 0%, #7030A0 100%);
  --antTableHeaderCellBackgroundColor: var(--secondaryColor);
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  overflow: hidden;
  /* background-image: url('../public/mainBg4.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}



.ant-modal-title {
  text-align: center;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--antTableHeaderCellBackgroundColor);
  color: var(--white);
}

.ant-layout {
  height: 100%;
  background: transparent;
}

.ant-layout .ant-layout-header {
  background: rgb(0, 0, 36);
  /* background: linear-gradient(90deg, rgba(0, 0, 36, 1) 0%, rgba(76, 9, 121, 1) 35%, rgba(158, 0, 255, 1) 100%); */
  background: var(--mainBackgroundColor);
  padding: 0;
  height: 100px !important;
}

.reportLayout .ant-layout-header {
  height: auto !important;
}

.ant-layout .ant-layout-content {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;
}

.ant-layout .ant-layout-header .ant-image {
  height: 100px !important
}

.ant-tabs-nav-wrap {
  white-space: initial !important;
}

.ant-tabs-tab {
  text-align: center !important;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* Search input styles */
.ant-input-search {
  height: 40px;
}

.ant-input-search .ant-input-wrapper {
  height: 100%;
}

.ant-input-search .ant-input-wrapper .ant-input {
  height: 100%;
}

.ant-input-search .ant-input-wrapper .ant-input-group-addon {
  height: 100%;
}

.ant-input-search .ant-input-wrapper .ant-input-group-addon .ant-btn {
  height: 100%;
}

:where(.css-dev-only-do-not-override-dpdwt0).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
  background-color: #F3E2FF
}

:where(.css-dev-only-do-not-override-dpdwt0).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  color: #ffffff;
  background: #7030A0;
}

:where(.css-dev-only-do-not-override-dpdwt0).ant-select .ant-select-arrow {
  color: #ffffff;
}

/* Icon center alignment */
.ant-layout-content .anticon {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Heading styles */
h2 {
  margin-bottom: 0;
}

.binCardContainer,
.createButtonContainer {
  min-height: 280px;
}