.mainGraph {
    padding:0 6em;
    margin: 2em;
}

.graphTitle {
    text-align: center;
}

@media (max-width: 600px) {
    .mainGraph {
        padding: 0 0.5em;
        margin: 0.5em;
    }
}