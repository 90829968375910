/* Container Styles */
.feedback-container {
    padding: 24px;
    background: linear-gradient(135deg, #f0f2f5 25%, #ffffff 100%);
}

/* General Styles */

.filter-comp {
    display: flex;
    align-items: center;
    padding: 8px;
}

.filter-comp > label {
    font-size: 16px;
    margin-right: 8px;
    white-space: nowrap;
    color: #595959;
    font-weight: 500;
}


.chart-title,
.feedbackTableTitle {
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    color: #1890ff;
}

.radarchartContainerCS .ant-card-body {
    width: 100% !important;
}


.radarchartContainerCS,
.feedbackTable {
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.radarchartContainerCS:hover,
.feedbackTable:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.ant-table-wrapper {
    overflow-x: auto;
}

.no-feedback {
    text-align: center;
    font-size: 1em;
    color: #888;
    padding: 24px 0;
}

/* Custom Select Styles */
.ant-select-selector {
    border-radius: 8px !important;
}

.ant-select-selection-item {
    font-weight: 500;
}

/* Responsive Styles */
@media (max-width: 576px) {
    .filter-comp {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0;
    }

    .filter-comp > label {
        margin-bottom: 8px;
    }

    .feedbackTable {
        padding: 16px;
    }

    .feedbackTableTitle {
        font-size: 1.25em;
    }
}


/* Unique Design Elements */
.feedback-container {
    background: linear-gradient(135deg, #e6f7ff 25%, #ae9cff 100%);
}

.chart-title::after,
.feedbackTableTitle::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    margin: 8px auto 0;
}

.ant-card-hoverable {
    transition: transform 0.2s;
}

.ant-card-hoverable:hover {
    transform: translateY(-4px);
}
