.csvActionsContainer {
    margin: 0 auto;
    display: flex;
    justify-content: end;
}

.csvActionsContainer .ant-row{
    padding-top: 24px;
}

.csvActionsContainer .ant-button {
    width: 20%;
    padding-right: 2%;
}
