.csvViewHeader .ant-col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2%;
}

.csvViewTableContainer {
    width: 100%;
    margin: 0 auto;
}

.csvViewTableContainer .ant-table-thead .ant-table-cell {
    background-color: #252525;
    color: white;
}
